$primary-color: #0074D9;
$success-color: #2ECC40;
$warning-color: #FFDC00;
$error-color: #FF4136;

@import "spectre/spectre.scss";
@import "spectre/spectre-exp.scss";
@import "spectre/spectre-icons.scss";

@import url('https://rsms.me/inter/inter.css');
html, body {
  padding-top: 20px;
  font-family: 'Inter', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important;
}
@supports (font-variation-settings: normal) {
  html, body {
    padding-top: 20px;
    font-family: 'Inter var', -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif !important;
  }
}

.table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-variant-numeric: lining-nums tabular-nums;
  font-feature-settings: 'tnum' 1;
}

.rules {
  margin-top: 2rem !important;
}

.release-notes {
  padding: 2rem 0;
  h1 {
    text-align: center;
  }
  h2.h3 {
    padding-top: 2rem;
    border-bottom: 1px solid #ddd;
  }
  h3.h4 {
    margin-bottom: 0.2rem;
  }
  ul {
    margin: 0 0 1rem 1.5rem;
  }
}

.navbar-section {
  font-size: 8px;
}

@media only screen and (min-width: 960px) {
  .navbar-brand {
    border-right: 1px solid #666;
    padding-right: 0.5rem;
  }
}

@media only screen and (min-width: 1056px) {
  table {
    display: table !important;
  }
}
